import React, { useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import instance from "../../../axiosInstance";
import { useClaimFormContext } from "../../../context/administration/claimForm";
import ContactInput from "../../UI/forms/ContactInput";
import CustomDate from "../../UI/forms/CustomDate";
import { titleOptions } from "../AddNewCase/AddNewCaseForm";
import { booleanOptions } from "../ClaimForm/ClientDetails";
import MapComponent from "./MapWithSearch";

//fetching Combos/ Dropdown Options
let fetchedTypesOfAccident = [];
export const fetchTypesOfAccident = async (search) => {
  try {
    if (search && fetchedTypesOfAccident.length) {
      const filteredOptions = fetchedTypesOfAccident.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
      return {
        options: filteredOptions,
        hasMore: false,
      };
    }

    const res = await instance.get("/lookups/typesOfAccident");
    const { data } = res.data;
    console.log(data, 0);
    const options = data.map((status) => ({
      value: status,
      label: status,
    }));

    fetchedTypesOfAccident = options;

    return {
      options,
      hasMore: false,
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

let fetchedPassengersQuantity = [];
export const fetchPassengersQuantity = async (search) => {
  try {
    if (search && fetchedPassengersQuantity.length) {
      const filteredOptions = fetchedPassengersQuantity.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
      return {
        options: filteredOptions,
        hasMore: false,
      };
    }

    const res = await instance.get("/lookups/passengersQuantity");
    const { data } = res.data;
    console.log(data, 0);
    const options = data.map((status) => ({
      value: status,
      label: status,
    }));

    fetchedPassengersQuantity = options;

    return {
      options,
      hasMore: false,
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

export const genderOptions = [
  { value: "Not Known", label: "Not Known" },
  { value: "Female", label: "Female" },
  { value: "Male", label: "Male" },
];

export const consultedOptions = [
  { value: "GP/Doctor ", label: "GP/Doctor" },
  { value: "Other", label: "Other" },
];

let fetchedAccidentCountriesOptions = [];
const fetchAccidentCountriesOptions = async (search) => {
  try {
    if (search && fetchedAccidentCountriesOptions.length) {
      const filteredOptions = fetchedAccidentCountriesOptions.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
      return {
        options: filteredOptions,
        hasMore: false,
      };
    }

    const res = await instance.get("/lookups/accidentCountries");
    const { data } = res.data;
    const options = data.map((status) => ({
      value: status,
      label: status,
    }));

    fetchedAccidentCountriesOptions = options;

    return {
      options,
      hasMore: false,
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

let fetchedAccidentTypesOptions = [];
const fetchAccidentTypesOptions = async (search) => {
  try {
    if (search && fetchedAccidentTypesOptions.length) {
      const filteredOptions = fetchedAccidentTypesOptions.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
      return {
        options: filteredOptions,
        hasMore: false,
      };
    }

    const res = await instance.get("/lookups/caseTypes");
    const { data } = res.data;
    const options = data.map((status) => ({
      value: status,
      label: status,
    }));

    fetchedAccidentTypesOptions = options;

    return {
      options,
      hasMore: false,
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

export const loadTemplates = async (search, loadedOptions, { page }) => {
  try {
    const res = await instance.get("/accidentTemplate/lists", {
      params: { search, page, limit: 10 },
    });

    const { data } = res.data;
    const options = data.data.map((template) => ({
      value: template.description,
      label: template.title,
    }));

    return {
      options,
      hasMore: page < data.total_pages,
      additional: {
        page: page + 1,
      },
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

const AccidentOrInjury = ({ caseId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const {
    accident_type,
    setAccidentType,
    accident_date,
    setAccidentDate,
    accident_time,
    setAccidentTime,
    accident_location,
    setAccidentLocation,
    accident_description,
    setAccidentDescription,
    injury_description,
    setInjuryDescription,
    wearing_seat_belt,
    setWearingSeatBelt,
    google_lat_lon,
    setGoogleLatLon,
    suffering_time,
    setSufferingTime,
    medical_attention,
    setMedicalAttention,
    previous_accidents,
    setPreviousAccidents,
    why_you_not_claim,
    setWhyYouNotClaim,
    sent_for_medical,
    setSentForMedical,
    did_any_paperwork,
    setDidAnyPaperwork,
    reported_to_police,
    setReportedToPolice,
    police_report,
    setPoliceReport,
    were_there_witness,
    setWereThereWitness,
    witness_info,
    setWitnessInfo,
    witness_title,
    setWitnessTitle,
    witness_first_name,
    setWitnessFirstName,
    witness_last_name,
    setWitnessLastName,
    witness_contact,
    setWitnessContact,
    were_there_passenger,
    setWereTherePassenger,
    passenger_info,
    setPassengerInfo,
    liability_terms,
    setLiabilityTerms,
    tp_admit_liability,
    setTpAdmitLiability,
    tp_offered_service,
    setTpOfferedService,
    offered_service_detail,
    setOfferedServiceDetail,
    country_of_accident,
    setCountryOfAccident,
    accidentValidation,
    setAccidentValidation,

    type_of_accident,
    set_type_of_accident,
    passengers_in_vehicle,
    set_passengers_in_vehicle,
    third_party_driver_description,
    set_third_party_driver_description,
    driver_gender,
    set_driver_gender,
    driver_age_approx,
    set_driver_age_approx,
    third_party_driver_insurance_details,
    set_third_party_driver_insurance_details,
    details_obtained_from,
    set_details_obtained_from,
    time_off_work_taken_due_to_injury,
    set_time_off_work_taken_due_to_injury,
    client_currently_off_work,
    set_client_currently_off_work,
    total_days_off_work,
    set_total_days_off_work,
    medical_attention_sought,
    set_medical_attention_sought,
    medical_professional_consulted,
    set_medical_professional_consulted,
    recommended_followup_treatment,
    set_recommended_followup_treatment,
    hospital_attended,
    set_hospital_attended,
    hospital_name,
    set_hospital_name,
    hospital_stay_nights,
    set_hospital_stay_nights,
    claim_for_losses_required,
    set_claim_for_losses_required,
  } = useClaimFormContext();

  return (
    <Form>
      <h4>Accident/Injury Details</h4>

      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="accidentType">Accident Type</Label>
            <AsyncPaginate
              className={`basic-single ${accidentValidation.accidentType ? "invalid-select" : ""}`}
              classNamePrefix="select"
              isSearchable
              isClearable
              name="accidentType"
              loadOptions={fetchAccidentTypesOptions}
              additional={{
                page: 1,
              }}
              debounceTimeout={300}
              value={accident_type}
              onChange={(value) => {
                setAccidentType(value);
                setAccidentValidation({ ...accidentValidation, accidentType: value ? "" : "Accident type is required" });
              }}
            />
            <small className="text-danger">{accidentValidation.accidentType}</small>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="accidentDate">Accident Date</Label>
            <Col md={6} className="pl-0">
              <CustomDate
                id="accidentDate"
                className={accidentValidation.accidentDate ? "is-invalid" : ""}
                startDate={accident_date}
                setStartDate={(date) => {
                  setAccidentDate(date);
                  setAccidentValidation({
                    ...accidentValidation,
                    accidentDate: date ? "" : "Accident date is required",
                  });
                }}
              />
              <small className="text-danger">{accidentValidation.accidentDate}</small>
            </Col>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="accidentTime">Accident Time</Label>
            <Col md={6} className="pl-0">
              <DatePicker
                selected={accident_time}
                onChange={(date) => {
                  setAccidentTime(date);
                  setAccidentValidation({
                    ...accidentValidation,
                    accidentTime: date ? "" : "Accident time is required",
                  });
                }}
                autoComplete="off"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={10}
                timeCaption="Time"
                className={`form-control ${accidentValidation.accidentTime ? "is-invalid" : ""}`}
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                placeholderText="Select time"
              />
              <small className="text-danger">{accidentValidation.accidentTime}</small>
            </Col>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="accidentLocation">Accident Location</Label>
            <Input type="text" autoComplete="off" id="accidentLocation" name="accidentLocation" value={accident_location} onChange={(e) => setAccidentLocation(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <div className="d-flex justify-content-between">
              <Label for="accidentDescription">Description of Accident</Label>

              <div className="w-120">
                <AsyncPaginate
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable
                  name="preferredLanguage"
                  placeholder="Template"
                  loadOptions={loadTemplates}
                  additional={{
                    page: 1,
                  }}
                  debounceTimeout={300}
                  // value={preferred_language}
                  onChange={(selected) => setAccidentDescription(selected.value)}
                />
              </div>
            </div>
            <Input
              type="textarea"
              autoComplete="off"
              id="accidentDescription"
              name="accidentDescription"
              rows="1"
              value={accident_description}
              onChange={(e) => setAccidentDescription(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="injuryDescription"> Injury</Label>
            <Input type="textarea" autoComplete="off" id="injuryDescription" name="injuryDescription" rows="1" value={injury_description} onChange={(e) => setInjuryDescription(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="wearingSeatBelt">Were you wearing a seatbelt?</Label>
            <Select
              id="wearingSeatBelt"
              name="wearingSeatBelt"
              className="basic-single"
              classNamePrefix="select"
              value={wearing_seat_belt}
              onChange={(value) => setWearingSeatBelt(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Label for="googlelatlon">Google Maps</Label>
            <Input type="text" autoComplete="off" id="googlelatlon" name="googlelatlon" value={google_lat_lon} onChange={(e) => setGoogleLatLon(e.target.value)} />
            {/* <Input type="text" autoComplete="off" id="googlelatlon" name="googlelatlon" value={google_lat_lon} onChange={(e) => setGoogleLatLon(e.target.value)} onClick = {()=> setIsModalOpen(true)}/> */}
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="sufferingTime">Suffering Time</Label>
            <Input type="text" autoComplete="off" id="sufferingTime" name="sufferingTime" value={suffering_time} onChange={(e) => setSufferingTime(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="medicalAttention">Medical Attention</Label>
            <Input type="text" autoComplete="off" id="medicalAttention" name="medicalAttention" value={medical_attention} onChange={(e) => setMedicalAttention(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="previousAccident">Have you had any other accidents in the last 3 years?</Label>
            <Input type="text" autoComplete="off" id="previousAccident" name="previousAccident" value={previous_accidents} onChange={(e) => setPreviousAccidents(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="claimHistory">Why have you not made a claim until now?</Label>
            <Input type="textarea" autoComplete="off" id="claimHistory" name="claimHistory" rows="1" value={why_you_not_claim} onChange={(e) => setWhyYouNotClaim(e.target.value)} />
            <div className="text-danger">Only ask if accident is more than 3 months ago</div>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="sentForMedical">Have you been sent for a medical?</Label>
            <Select
              id="sentForMedical"
              name="sentForMedical"
              className="basic-single"
              classNamePrefix="select"
              value={sent_for_medical}
              onChange={(value) => setSentForMedical(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="didAnyPaperwork">Have you spoken or signed any paperwork with any other companies?</Label>
            <Select
              id="didAnyPaperwork"
              name="didAnyPaperwork"
              className="basic-single"
              classNamePrefix="select"
              value={did_any_paperwork}
              onChange={(value) => setDidAnyPaperwork(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="reportedToPolice">Was the accident reported to the police?</Label>
            <Select
              id="reportedToPolice"
              name="reportedToPolice"
              className="basic-single"
              classNamePrefix="select"
              value={reported_to_police}
              onChange={(value) => setReportedToPolice(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>
        {reported_to_police.value === "Yes" && (
          <Col md={4}>
            <FormGroup>
              <Label for="policeReport">Police Report Details</Label>
              <Input type="textarea" autoComplete="off" id="policeReport" name="policeReport" rows="1" value={police_report} onChange={(e) => setPoliceReport(e.target.value)} />
            </FormGroup>
          </Col>
        )}
        <Col md={4}>
          <FormGroup>
            <Label for="anyWitnesses">Were there witnesses?</Label>
            <Select
              id="anyWitnesses"
              name="anyWitnesses"
              className="basic-single"
              classNamePrefix="select"
              value={were_there_witness}
              onChange={(value) => setWereThereWitness(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>
        {were_there_witness.value === "Yes" && (
          <>
            <Col md={4}>
              <FormGroup>
                <Label for="witnessInfo">Witness Info</Label>
                <Input type="textarea" autoComplete="off" id="witnessInfo" name="witnessInfo" rows="1" value={witness_info} onChange={(e) => setWitnessInfo(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="witnessTitle">Witnesses Title</Label>
                <Select
                  className={`basic-single`}
                  classNamePrefix="select"
                  id="witnessTitle"
                  name="witnessTitle"
                  value={witness_title}
                  options={titleOptions}
                  onChange={(value) => {
                    setWitnessTitle(value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="witnessFirstName">Witnesses First Name</Label>
                <Input type="text" autoComplete="off" id="witnessFirstName" name="witnessFirstName" value={witness_first_name} onChange={(e) => setWitnessFirstName(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="witnessLastName">Witnesses Last Name</Label>
                <Input type="text" autoComplete="off" id="witnessLastName" name="witnessLastName" value={witness_last_name} onChange={(e) => setWitnessLastName(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <ContactInput
                name="witnessContact"
                label="Witnesses Contact"
                value={witness_contact}
                onChange={setWitnessContact}
                isInvalid={accidentValidation.witnessContact}
                setError={[setAccidentValidation, "witnessContact"]}
              />
            </Col>
          </>
        )}
        <Col md={4}>
          <FormGroup>
            <Label for="anyPassenger">Were there any passengers in the vehicle?</Label>
            <Select
              id="anyPassenger"
              name="anyPassenger"
              className="basic-single"
              classNamePrefix="select"
              value={were_there_passenger}
              onChange={(value) => setWereTherePassenger(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>
        {were_there_passenger.value === "Yes" && (
          <Col md={4}>
            <FormGroup>
              <Label for="passengerInfo">Seating Plan</Label>
              <Input type="textarea" autoComplete="off" id="passengerInfo" name="passengerInfo" rows="1" value={passenger_info} onChange={(e) => setPassengerInfo(e.target.value)} />
            </FormGroup>
          </Col>
        )}
        <Col md={4}>
          <FormGroup>
            <Label for="liabilityTerms">What has happened in terms of liability?</Label>
            <Input type="textarea" autoComplete="off" id="liabilityTerms" name="liabilityTerms" rows="1" value={liability_terms} onChange={(e) => setLiabilityTerms(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="tpAdmitLiability">Did the TP admit liability at the Scene?</Label>
            <Select
              id="tpAdmitLiability"
              name="tpAdmitLiability"
              className="basic-single"
              classNamePrefix="select"
              value={tp_admit_liability}
              onChange={(value) => setTpAdmitLiability(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="tpOfferedService">Has the TPI offered any types of services or been in contact?</Label>
            <Select
              id="tpOfferedService"
              name="tpOfferedService"
              className="basic-single"
              classNamePrefix="select"
              value={tp_offered_service}
              onChange={(value) => setTpOfferedService(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>
        {tp_offered_service.value === "Yes" && (
          <Col md={4}>
            <FormGroup>
              <Label for="offeredServiceDetail">Offered Service Details</Label>
              <Input
                type="textarea"
                autoComplete="off"
                id="offeredServiceDetail"
                name="offeredServiceDetail"
                rows="1"
                value={offered_service_detail}
                onChange={(e) => setOfferedServiceDetail(e.target.value)}
              />
            </FormGroup>
          </Col>
        )}
        <Col md={4}>
          <FormGroup>
            <Label for="countryOfAccident">Which country did the accident occur in?</Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable
              name="countryOfAccident"
              loadOptions={fetchAccidentCountriesOptions}
              additional={{
                page: 1,
              }}
              debounceTimeout={300}
              value={country_of_accident}
              onChange={(value) => setCountryOfAccident(value)}
            />
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Label for="type_of_accident"> Select type of accident </Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="type_of_accident"
              id="type_of_accident"
              loadOptions={fetchTypesOfAccident}
              value={type_of_accident}
              onChange={(selected) => set_type_of_accident(selected)}
            />
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Label for="passengers_in_vehicle"> Including your client, how many people were in the vehicle? </Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="passengers_in_vehicle"
              id="passengers_in_vehicle"
              loadOptions={fetchPassengersQuantity}
              value={passengers_in_vehicle}
              onChange={(selected) => set_passengers_in_vehicle(selected)}
            />
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Label for="third_party_driver_description"> How did the bike leave the scene of the accident? </Label>
            <Input
              type="textarea"
              autoComplete="off"
              rows="1"
              id="third_party_driver_description"
              value={third_party_driver_description}
              onChange={(e) => set_third_party_driver_description(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Label for="driver_gender"> Gender of the driver </Label>
            <Select id="driver_gender" className="basic-single" classNamePrefix="select" value={driver_gender} onChange={(value) => set_driver_gender(value)} options={genderOptions} />
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Label for="driver_age_approx"> Approximate Age </Label>
            <Input type="number" autoComplete="off" id="driver_age_approx" value={driver_age_approx} onChange={(e) => set_driver_age_approx(e.target.value)} />
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Label for="third_party_driver_insurance_details"> Do you know the third party driver insurance details? </Label>
            <Select
              id="third_party_driver_insurance_details"
              className="basic-single"
              classNamePrefix="select"
              value={third_party_driver_insurance_details}
              onChange={(value) => set_third_party_driver_insurance_details(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Label for="details_obtained_from"> How did you obtain these details, eg. at the scene, from the police </Label>
            <Input type="text" autoComplete="off" id="details_obtained_from" value={details_obtained_from} onChange={(e) => set_details_obtained_from(e.target.value)} />
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Label for="time_off_work_taken_due_to_injury"> Did you take any time off work because of their injury? </Label>
            <Select
              id="time_off_work_taken_due_to_injury"
              className="basic-single"
              classNamePrefix="select"
              value={time_off_work_taken_due_to_injury}
              onChange={(value) => set_time_off_work_taken_due_to_injury(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>

        {time_off_work_taken_due_to_injury?.value === "Yes" && (
          <Col md={4}>
            <FormGroup>
              <Label for="client_currently_off_work"> Is your client still off work? </Label>
              <Select
                id="client_currently_off_work"
                className="basic-single"
                classNamePrefix="select"
                value={client_currently_off_work}
                onChange={(value) => set_client_currently_off_work(value)}
                options={booleanOptions}
              />
            </FormGroup>
          </Col>
        )}

        {client_currently_off_work?.value === "No" && (
          <Col md={4}>
            <FormGroup>
              <Label for="total_days_off_work"> How many days in total was you off work? </Label>
              <Input type="text" autoComplete="off" id="total_days_off_work" value={total_days_off_work} onChange={(e) => set_total_days_off_work(e.target.value)} />
            </FormGroup>
          </Col>
        )}

        <Col md={4}>
          <FormGroup>
            <Label for="medical_attention_sought"> Did you seek any medical attention as a result of the injuries they sustained in the accident? </Label>
            <Select
              id="medical_attention_sought"
              className="basic-single"
              classNamePrefix="select"
              value={medical_attention_sought}
              onChange={(value) => set_medical_attention_sought(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>

        {medical_attention_sought?.value === "Yes" && (
          <>
            <Col md={4}>
              <FormGroup>
                <Label for="medical_professional_consulted"> Who did you see? </Label>
                <Select
                  id="medical_professional_consulted"
                  className="basic-single"
                  classNamePrefix="select"
                  value={medical_professional_consulted}
                  onChange={(value) => set_medical_professional_consulted(value)}
                  options={consultedOptions}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="recommended_followup_treatment"> Did they recommend your client should undertake any further treatment such as physiotherapy? </Label>
                <Select
                  id="recommended_followup_treatment"
                  className="basic-single"
                  classNamePrefix="select"
                  value={recommended_followup_treatment}
                  onChange={(value) => set_recommended_followup_treatment(value)}
                  options={booleanOptions}
                />
              </FormGroup>
            </Col>
          </>
        )}

        <Col md={4}>
          <FormGroup>
            <Label for="hospital_attended"> Did you attend a hospital? </Label>
            <Select id="hospital_attended" className="basic-single" classNamePrefix="select" value={hospital_attended} onChange={(value) => set_hospital_attended(value)} options={booleanOptions} />
          </FormGroup>
        </Col>

        {hospital_attended?.value === "Yes" && (
          <>
            <Col md={4}>
              <FormGroup>
                <Label for="hospital_name"> Hospital Name </Label>
                <Input type="text" autoComplete="off" id="hospital_name" value={hospital_name} onChange={(e) => set_hospital_name(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="hospital_stay_nights"> How many nights did you stay in the hospital? </Label>
                <Input type="number" autoComplete="off" id="hospital_stay_nights" value={hospital_stay_nights} onChange={(e) => set_hospital_stay_nights(e.target.value)} />
              </FormGroup>
            </Col>
          </>
        )}

        <Col md={4}>
          <FormGroup>
            <Label for="claim_for_losses_required"> Do you want to claim for any losses? </Label>
            <Select
              id="claim_for_losses_required"
              className="basic-single"
              classNamePrefix="select"
              value={claim_for_losses_required}
              onChange={(value) => set_claim_for_losses_required(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>
      </Row>

      <Modal isOpen={isModalOpen} centered toggle={toggleModal}>
        <ModalHeader>Update the Form</ModalHeader>

        {/* -------------------------------------------------------- */}
        <Form className="m-4">
          <ModalBody>
            <MapComponent setLatLon={setGoogleLatLon} />
          </ModalBody>

          <ModalFooter>
            <Button color="primary" onClick={toggleModal} outline>
              Cancel
            </Button>
            <Button color="primary" onClick={toggleModal}>
              Save
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      {/* -------------------------------------------------------- */}
    </Form>
  );
};

export default AccidentOrInjury;
